import React from "react"
import styled from "styled-components"
import PageBlock from "../Blocks/PageBlock"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"

const PinkBlock = styled(PageBlock)``

const BlueBlock = styled(PageBlock)``

const WhiteBlock = styled(PageBlock)`
  h2 {
    color: ${colors.royalBlue};
    margin: 2rem 0;
    font-size: calc(2.4vw + 1rem);
    line-height: 1.104;
    font-weight: 900;
    @media (min-width: 1600px){
    font-size: 55px;
  }
    ${fonts.workSansBold}
  }
  p {
    margin: 1rem 0;
    line-height: 1.6;
    b {
      ${fonts.workSansBold}
    }
    i {
      font-style: italic;
    }
    a {
      text-underline-offset: 0.2em;
      color: ${colors.royalBlue};
    }
  }
`

const Blocks = (props) => {
  const blocks = props.blocks

  return (
    <>
      {blocks && blocks.map((block, index) => {
        const { backgroundColor } = block
        return backgroundColor === "Pink" ? (
          <PinkBlock key={index} block={block} />
        ) : backgroundColor === "Blue" ? (
          <BlueBlock key={index} block={block} />
        ) : backgroundColor === "White" ? (
          <WhiteBlock key={index} block={block} />
        ) : null
      })}
    </>
  )
}

export default Blocks
