import React from "react"
import Layout, {
  MOBILE_HEADER_HEIGHT,
  DESKTOP_HEADER_HEIGHT,
} from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import Blocks from "../components/Page/Blocks"
import styled from "styled-components"
import InstagramLink from "../components/Core/InstagramLink"
import ContactForm from "../components/Forms/ContactForm"
import colors from "../styles/colors"
import fonts from "../styles/fonts"
import breakpoints from "../styles/breakpoints"

const Heading3 = styled.h3`
  ${fonts.workSansBold}
  color: ${colors.royalBlue};
  margin-top: 2rem;
  line-height: 1.176;
  padding-bottom: 34px;
`

const PageContainer = styled.div`
  padding-top: calc(4vmax + ${DESKTOP_HEADER_HEIGHT}px);
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 6.6vmax;

  @media (max-width: ${breakpoints.md}) {
    padding-top: calc(4vmax + ${MOBILE_HEADER_HEIGHT}px);
  }

  @media (min-width: ${breakpoints.md}) {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
`

const FormContainer = styled.div`
  @media (min-width: ${breakpoints.md}) {
    grid-column: 7 / 13;
    padding: 17px;
  }
`

const BlockContainer = styled.div`
  @media (min-width: ${breakpoints.md}) {
    grid-column: 1 / 6;
    padding: 17px;
  }
`

const ContactPage = (props) => {
  const { location, data } = props
  const path = location.pathname

  const page = data.contentfulPage

  let sharingImage = false
  if (page.socialMediaImage) {
    sharingImage = page.socialMediaImage.file.url
  }

  return (
    <Layout dark bg={colors.lightGrey}>
      <Seo
        title={page.metaTitle}
        description={page.metaDescription}
        fullTitle={true}
        path={path}
        image={sharingImage}
      />
      <PageContainer>
        <BlockContainer>
          <Blocks blocks={page.blocks} />
          <Heading3>Follow us</Heading3>
          <InstagramLink />
        </BlockContainer>
        <FormContainer>
          <Heading3>Send Us a Message</Heading3>
          <ContactForm />
        </FormContainer>
      </PageContainer>
    </Layout>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query ContactQuery {
    contentfulPage(slug: { eq: "contact" }) {
      ...pageFragment
    }
  }
`
