/* global fbq */
import React, { useState } from "react"
import Select from "react-select"
import styled from "styled-components"
import axios from "axios"
import { inputStyle, submitButtonStyle } from "./Styles"
import { selectStyles } from "src/styles/select"
import colors from "src/styles/colors"
import {hashEmail} from "src/services/util/hash"

const REASONS_FOR_ENQUIRY = [
  { value: "General customer inquiry", label: "General customer inquiry" },
  { value: "Order issue", label: "Order issue" },
  { value: "Bring IGC to my city!", label: "Bring IGC to my city!" },
  { value: "Press", label: "Press" },
  {
    value: "Chef/Restaurant interested in collaborating",
    label: "Chef/Restaurant interested in collaborating",
  },
  { value: "Other", label: "Other" },
]

const FormContainer = styled.div`
  line-height: 1.6em;
`

const InputContainerGroup = styled.div`
  display: flex;
  flex-direction: row;
`

const InputContainer = styled.div`
  margin-bottom: 24px;
  margin-right: 2%;
`

const InputContainerHalfWidth = styled.div`
  margin-bottom: 24px;
  width: 48%;
  margin-right: 2%;
`

const Input = styled.input`
  ${inputStyle}
`

const Textarea = styled.textarea`
  ${inputStyle}
  resize: none;
`

const FormLabel = styled.label``

const Submit = styled.button`
  ${submitButtonStyle}
`

const ErrorText = styled.p`
  padding-top: 17px;
`

const styledOptionStyles = () => {
  return {
    ...selectStyles(),
    control: (styles) => ({
      ...styles,
      borderRadius: "2px",
      border: `1px solid #ccc`,
      padding: "12px",
      boxShadow: "none",
      cursor: "pointer",
      minHeight: "unset",
      fontSize: "12px",
      lineHeight: "normal",
      background: "transparent",
      margin: "6px 0 4px",
      color: "#000",
      background: "#fafafa",
      ":hover": {
        borderColor: colors.midGrey,
      },
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "12px",
      lineHeight: "normal",
    }),
  }
}

const ContactForm = () => {
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [email, setEmail] = useState()
  const [reasonForInquiry, setReasonForInquiry] = useState(
    REASONS_FOR_ENQUIRY[0].value
  )
  const [message, setMessage] = useState()
  const [statusMessage, setStatusMessage] = useState("")

  const handleSubmit = async (event) => {
    event.preventDefault()

    const url = "/.netlify/functions/contact"

    const data = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      reasonForInquiry: reasonForInquiry.value,
      message: message,
    }

    let old_gtag_data = {
      event_category: "engagement",
      event_label: "contact_page",
      send_to: "UA-185335499-1"
      // firstName: firstName,
      // lastName: lastName,
      // email: email,
      // reasonForInquiry: reasonForInquiry.value,
      // message: message
    }

    if (typeof window.gtag !== 'undefined') {
    try{
      window.gtag('event', 'form_submit', old_gtag_data );

    } catch (e) {
      console.log(e)
    }
      let gtag_data = {
        method: 'contact page form',
        send_to: "G-LZ50YRTV2Y"
      };
      
      window.gtag('event', 'sign_up', gtag_data);
      console.log('gtag success')
    }
    try{
      fbq('track', 'Lead', {
        content_name: 'Email Signup',
        content_category: 'Newsletter',
        user_data: {
          em : hashEmail(data.email)
        }
      });
    } catch(e)
    {
      console.log(e)
    }

    try {
      const response = await axios.post(url, data)
      if (response.status === 200) {
        setStatusMessage("Submitted!")
        setFirstName("")
        setLastName("")
        setEmail("")
        setMessage("")
        setReasonForInquiry(null)
      }
    } catch {
      setStatusMessage("Error")
    } finally {
      setTimeout(() => {
        setStatusMessage("")
      }, 6000);
    }
  }

  return (
    <FormContainer>
      <form onSubmit={handleSubmit}>
        <InputContainerGroup>
          <InputContainerHalfWidth>
            <FormLabel>First name *</FormLabel>
            <Input
              type="text"
              name="contact[firstName]"
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
              required
            />
          </InputContainerHalfWidth>
          <InputContainerHalfWidth>
            <FormLabel>Last name</FormLabel>
            <Input
              type="text"
              name="contact[lastName]"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
            />
          </InputContainerHalfWidth>
        </InputContainerGroup>
        <InputContainer>
          <FormLabel>Email *</FormLabel>
          <Input
            type="text"
            name="contact[email]"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
          />
        </InputContainer>
        <InputContainer>
          <FormLabel>Reason for Inquiry *</FormLabel>
          <Select
            onChange={(e) => setReasonForInquiry(e)}
            options={REASONS_FOR_ENQUIRY}
            value={reasonForInquiry}
            isSearchable={false}
            styles={styledOptionStyles()}
          />
        </InputContainer>
        <InputContainer>
          <FormLabel>Message *</FormLabel>
          <Textarea
            type="text"
            name="contact[message]"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            required
          ></Textarea>
        </InputContainer>
        <Submit type="submit">
          {statusMessage === "" ? "Send" : statusMessage}
        </Submit>
      </form>
    </FormContainer>
  )
}

export default ContactForm
